var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',[_c('v-row',{staticClass:"pt-5 pb-5"},[_c('v-col',{attrs:{"cols":"10"}},[_c('h2',[_vm._v(" Detalle de cliente / "+_vm._s(_vm.clienteInfo.nombreCliente)+" ")])])],1)],1),_c('InfoCardCliente',{attrs:{"infoCards":_vm.infoCards,"emailRoutesConfig":_vm.emailRoutesConfig,"clienteID":_vm.clienteInfo.id},on:{"getData":_vm.obtnerDatosClientes}}),_c('div',[_c('v-row',{staticStyle:{"padding-top":"60px"}},[_c('v-col',{class:_vm.tabReplace == 1
            ? 'text-center active-color'
            : 'text-center inactive-color',attrs:{"cols":"4"},on:{"click":function($event){return _vm.tabReplaceSelect(1)}}},[_c('span',{class:_vm.tabReplace == 1
              ? 'text-header-step-active'
              : 'text-header-step-no-active'},[_vm._v(" Cliente")])]),_c('v-col',{class:_vm.tabReplace == 2
            ? 'text-center active-color'
            : 'text-center inactive-color',attrs:{"cols":"4"},on:{"click":function($event){return _vm.tabReplaceSelect(2)}}},[_c('span',{class:_vm.tabReplace == 2
              ? 'text-header-step-active'
              : 'text-header-step-no-active'},[_vm._v(" Pólizas ")])]),_c('v-col',{class:_vm.tabReplace == 3
            ? 'text-center active-color'
            : 'text-center inactive-color',attrs:{"cols":"4"},on:{"click":function($event){return _vm.tabReplaceSelect(3)}}},[_c('span',{class:_vm.tabReplace == 3
              ? 'text-header-step-active'
              : 'text-header-step-no-active'},[_vm._v(" Solicitudes")])])],1)],1),(_vm.tabReplace == 1)?_c('div',[_c('div',{staticStyle:{"padding-top":"60px"}},[_c('ClienteDataIndex',{attrs:{"clienteInfo":_vm.clienteInfo},on:{"getData":_vm.obtnerDatosClientes}})],1),_c('div',{staticClass:"pt-5"},[_c('ClienteContactIndex',{attrs:{"clienteInfo":_vm.clienteInfo},on:{"getData":_vm.realoadView}})],1),_c('div',{staticClass:"pt-5"},[_c('FiscalDatatIndex',{attrs:{"clienteInfo":_vm.clienteInfo},on:{"getData":_vm.obtnerDatosClientes}})],1),_c('div',{staticClass:"pt-5"},[_c('LogClienteIndex',{attrs:{"clienteInfo":_vm.clienteInfo}})],1),(_vm.rol == 'MESACONTROLINBOUND' || _vm.rol == 'OPERADORINBOUND' || _vm.rol == 'OPERACIONESINBOUND' || _vm.rol == 'ADMIN')?_c('EmbeddedCliente',{attrs:{"telefonos":_vm.telefonosCliente,"cliente_id":_vm.clienteInfo.id,"is_solicitud":true},on:{"success":()=>{_vm.location.reload()}}}):_vm._e()],1):_vm._e(),(_vm.tabReplace == 2)?_c('div',[_c('div',{staticStyle:{"padding-top":"60px"}},[_c('EmisionClienteIndex',{attrs:{"polizasCliente":_vm.polizasCliente,"clienteInfo":_vm.clienteInfo}})],1)]):_vm._e(),(_vm.tabReplace == 3)?_c('div',[_c('div',{staticStyle:{"padding-top":"60px"}},[_c('SolicitudClienteIndex',{attrs:{"solicitudesCliente":_vm.solicitudesCliente}})],1)]):_vm._e(),_c('div',[(_vm.dialogInboundCalls)?_c('CommonCreateInboundCallsModal',{attrs:{"dialog":_vm.dialogInboundCalls,"cliente":_vm.clienteInfo},on:{"closeModal":function($event){_vm.dialogInboundCalls = false},"success":function($event){_vm.dialogInboundCalls = false}}}):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }