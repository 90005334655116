<template>
  <div>
    <v-row>
      <v-col cols="10" md="10">
        <span class="titulo-header">Solicitudes</span>
      </v-col>
      <v-col cols="12" md="2" v-if="rol != 'AGENTCORP'">
        <v-btn class="common-botton" dark block @click="$router.push('/solicitud-crear')">
          <v-icon start> mdi-plus-circle </v-icon>
          Agregar
        </v-btn>
      </v-col>
    </v-row>
    <div style="padding-top: 30px;" v-if="globalConfig.showMainHubGraphs">
        <stats-solicitud></stats-solicitud>
    </div>
    <div style="padding-top: 30px;">
        <filters-solicitud-index ></filters-solicitud-index >
    </div>
  </div>
</template>


<script>

import FiltersSolicitudIndex from './FiltersSolicitudIndex.vue';
import StatsSolicitud from './StatsSolicitud.vue';

export default {
  components: {
    FiltersSolicitudIndex,
    StatsSolicitud
  },

  data() {
    return {
      globalConfig:JSON.parse(localStorage.getItem('globalConfig')),
      rol: JSON.parse(localStorage.getItem("roles"))[0].rol,
    };
  },
  methods: {
  },
};
</script>