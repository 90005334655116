<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <h2>Agregar asesor(es)</h2>
        <!-- Opciones de carga -->
        <v-radio-group v-model="tipoCarga">
          <v-radio label="Carga manual" value="manual" class="radios"></v-radio>

          <v-row class="form-card" v-if="tipoCarga === 'manual'">
            <v-col cols="12" md="4" class="padding-col">
              <v-text-field
                name="name"
                label="Nombre(s)"
                :rules="generalRules"
                v-model="usuario.nombre"
                outlined
                primary
                color="#00a7e4"
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="4" class="padding-col">
              <v-text-field
                name="apellidoPaterno"
                label="Apellido Paterno"
                :rules="generalRules"
                v-model="usuario.apellidoPaterno"
                outlined
                primary
                color="#00a7e4"
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="4" class="padding-col">
              <v-text-field
                name="apellidoMaterno"
                label="Apellido Materno"
                v-model="usuario.apellidoMaterno"
                outlined
                primary
                color="#00a7e4"
                dense
                :rules="generalRules"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="4" class="padding-col">
              <v-autocomplete
                v-model="usuario.sexo"
                :items="sexoList"
                item-text="sexo"
                item-value="id"
                label="Género"
                required
                :rules="generalRules"
                outlined
                primary
                color="#00a7e4"
                dense
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" md="4" class="padding-col">
              <v-text-field
                v-model="usuario.fechaNacimiento"
                label="Fecha de Nacimiento"
                placeholder="dd/mm/aaaa"
                outlined
                primary
                color="#00a7e4"
                dense
                type="date"
                :rules="generalRules"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="4" class="padding-col">
              <v-autocomplete
                item-text="nombre"
                item-value="id"
                v-model="usuario.nacimientoEstadoId"
                :items="estados"
                :rules="generalRules"
                label="Estado de nacimiento"
                outlined
                primary
                color="#00a7e4"
                dense
              ></v-autocomplete>
            </v-col>
            <!-- <v-col cols="12" md="4" class="padding-col">
              <v-text-field
                name="telefono"
                label="Teléfono  de  contacto"
                placeholder="##########"
                v-mask="'##########'"
                :rules="phoneRules"
                v-model="usuario.telefono"
                outlined
                primary
                color="#00a7e4"
                dense
              ></v-text-field>
            </v-col> -->
            <v-col cols="12" md="4" class="padding-col">
              <v-text-field
                label="Celular de contacto"
                placeholder="##########"
                :rules="phoneRules"
                v-mask="'##########'"
                v-model="usuario.celular"
                outlined
                primary
                color="#00a7e4"
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="4" class="padding-col">
              <v-text-field
                id="rfc"
                v-model="usuario.rfc"
                label="RFC"
                type="text"
                required
                placeholder="XAXX010101000"
                :rules="rfcRules"
                @input="onInputToUpper"
                outlined
                primary
                color="#00a7e4"
                dense
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="4">
              <v-row v-for="(element, index) in usuario.emails" :key="index">
                <v-col class="padding-col" cols="12">
                  <CommonEmailAutocomplete
                    @emailUpdate="(e) => (element.correo = e)"
                    :isDense="true"
                  >
                  </CommonEmailAutocomplete>
                </v-col>
              </v-row>
            </v-col>
            <!-- <v-col cols="12" md="4" class="padding-col">
              <v-autocomplete
                v-model="usuario.ramo"
                :items="ramoList"
                item-text="label"
                item-value="branchId"
                label="Ramo"
                multiple
                outlined
                primary
                color="#00a7e4"
                dense
                :rules="generalRules"
              >
                <template v-slot:selection="{ item, index }">
                  <v-chip
                    style="padding: 1px; border-radius: 5px; height: 20px"
                    color="primary"
                    v-if="index < 3"
                  >
                    <span style="padding: 3px; margin: 0">{{
                      item.label
                    }}</span>
                  </v-chip>
                  <span v-if="index === 3" class="grey--text text-caption">
                    (+{{ usuario.ramo.length - 3 }} más)
                  </span>
                </template>
              </v-autocomplete>
            </v-col>
            <v-col cols="12" md="4" class="padding-col">
              <v-autocomplete
                v-model="usuario.canal"
                :items="canalList"
                label="Canal"
                item-text="label"
                item-value="channelId"
                outlined
                primary
                color="#00a7e4"
                dense
                :rules="generalRules"
              >
              </v-autocomplete>
            </v-col> -->
            <v-row>
              <v-col cols="12" style="text-align: end" class="pt-10 pr-0">
                <v-btn
                  @click="abrirModal()"
                  class="common-botton asesor mr-4"
                  dark
                  rounded="xxl"
                  width="200"
                  :disabled="validador"
                >
                  Cargar asesor
                </v-btn>
              </v-col>
            </v-row>
          </v-row>

          <v-radio label="Carga masiva" value="masiva" class="radios"></v-radio>
        </v-radio-group>
      </v-col>
      <v-row justify="center" align="center" v-if="tipoCarga === 'masiva'">
        <v-col cols="10">
          <p style="margin: 0px 0px 0px 10px; font-size: 15px">
            <strong
              >Por favor apóyate del siguiente template para cargarlos una vez
              registrados en este archivo Excel</strong
            >
          </p>
        </v-col>
        <v-col cols="2" align="start">
          <a
            style="
              color: #039ecc;
              text-decoration: none;
              font-family: 'Montserrat';
              font-weight: 700;
            "
            href="https://storage.googleapis.com/bucketresourcescrmbradescard/Resorces%20CRM/Layout_definitivo.xlsx"
          >
            Descargar
            <v-icon color="#039ECC">mdi-download</v-icon>
          </a>
        </v-col>
      </v-row>

      <v-col cols="12" v-if="tipoCarga === 'masiva'">
        <v-card
          class="pa-4"
          outlined
          @dragover.prevent="onDragOver"
          @dragleave.prevent="onDragLeave"
          @drop.prevent="onDrop"
          :class="{ 'drag-active': isDragging }"
        >
          <v-row align="center">
            <v-col cols="6" class="d-flex align-center pl-6">
              <v-img class="icon-upload" :src="uploadicon"></v-img>
              <div style="margin-left: 15px">
                <p class="text-drag">Arrástrelo y suéltelo aquí</p>
                <p class="format-drag">Formato permitido: .xls .xlsx</p>
                <p class="format-drag">5 MB máximo</p>
              </div>
              <v-file-input
                v-model="file"
                color="#039ecc"
                accept=".xlsx, .xls"
                hide-input
                prepend-icon="mdi-plus-circle"
                outlined
                class="ml-10"
              >
              </v-file-input>
            </v-col>

            <v-col cols="4" class="text-left">
              <v-btn
                rounded
                @click="uploadFile"
                :disabled="!file"
                class="primary btn-archivo"
              >
                Cargar archivo
              </v-btn>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <ModalRegistroAsesor
      :dialog.sync="dialog"
      :asesor="usuario"
      :errorMessage="errorMessage"
      @confirmarRegistro="setNewUser"
      @setearValores="setearValores"
      @cambiarStep="cambiarStep"
      :step="stepIndividual"
    />
    <ModalCargaMasiva
      :dialog.sync="dialogCsv"
      :reporte="reporteCsv"
      @descargarExcel="downloadExcelFromCSV"
      :step="step"
      :progress="progress"
      @returnStep="returnStep"
    />
  </v-container>
</template>

<script>
import * as XLSX from "xlsx";
import uploadicon from "../../../../assets/upload-cloud.svg";
import moment from "moment";
import RfcFacil from "rfc-facil";
import { mainAxios } from "../../../../mainAxios";
import CommonEmailAutocomplete from "../../../../components/commonComponents/CommonEmailAutocomplete.vue";
import ModalRegistroAsesor from "./ModalRegistroAsesor.vue";
import ModalCargaMasiva from "./ModalCargaMasiva.vue";
import SockJS from "sockjs-client";
import Stomp from "stompjs";
export default {
  props: {
    idAliance: Number,
  },
  components: {
    CommonEmailAutocomplete,
    ModalRegistroAsesor,
    ModalCargaMasiva,
  },
  data() {
    return {
      errorMessage: "",
      file: null,
      isDragging: false,
      uploadicon,
      tipoCarga: "",
      reporteCsv: {
        ejecutados: 0,
        correctos: 0,
        duplicados: 0,
        rechazados: 0,
      },
      usuario: {
        nombre: "",
        apellidoPaterno: "",
        apellidoMaterno: "",
        rfc: "",
        sexo: "",
        fechaNacimiento: "",
        nacimientoEstadoId: "",
        celular: "",
        // telefono: "",
        correo: "",
        emails: [
          {
            correo: "",
            favorito: true,
          },
        ],
        status: 0,
        ramo: 0,
        canal: 0,
      },
      sexoList: [
        { id: 1, sexo: "Masculino" },
        { id: 2, sexo: "Femenino" },
      ],
      generalRules: [(v) => !!v || "Campo es necesario"],
      emailRules: [
        (v) => !!v || "E-mail es necesario",
        (v) =>
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            v
          ) || "E-mail no es valido",
      ],
      phoneRules: [
        (v) => !!v || "Campo es necesario",
        (v) => /^\d{10}$/.test(v) || "Mínimo 10 digitos",
        (v) =>
          !/^(.)\1{9}$/.test(v) ||
          "No se permiten secuencias de dígitos repetidos",
        (v) =>
          !["1111111111", "9999999999", "0000000000"].includes(v) ||
          "El número de teléfono no es válido",
      ],
      rfcRules: [
        (v) =>
          v.length === 10 ||
          v.length === 12 ||
          v.length === 13 ||
          "RFC debe tener 10, 12 o 13 caracteres",
        (v) => !!v || "Campo Requerido",
        (v) =>
          /^[A-Za-z\d]{10,13}$/.test(v) ||
          "RFC no es válido, asegúrate de tener la longitud correcta y utiliza caracteres alfanuméricos",
      ],
      estados: 0,
      canalList: [],
      ramoList: [],
      campaniaList: [],
      archivo: null,
      dialog: false,
      dialogCsv: false,
      step: 0,
      stepIndividual: 1,
      csvData: "",
      clientId: Math.random().toString(36).substring(2),
      stompClient: null,
      progress: 0,
    };
  },
  methods: {
    connectSocket() {
      let socket = new SockJS(
        `${process.env.VUE_APP_API_URL}/ws`
        // `http://localhost:8091/ws`
      );
      this.stompClient = Stomp.over(socket);

      this.stompClient.connect({}, (frame) => {
        console.log("Conectado: " + frame);

        // Suscribirse al canal del progreso
        this.stompClient.subscribe(
          `/topic/progress/${this.clientId}`,
          (progressUpdate) => {
            const progress = parseInt(progressUpdate.body, 10);
            this.progress = progress;
            console.log("Progreso: " + progress);
          }
        );
      });
    },
    cambiarStep() {
      this.stepIndividual = 1;
    },
    async uploadFile() {
      this.abrirModalCsv();
      try {
        if (this.file.size > 5 * 1024 * 1024) {
          console.error(
            "El archivo excede el tamaño máximo permitido de 5 MB."
          );
          return;
        }
        const formData = new FormData();
        formData.append("asesores", this.file);
        formData.append("clientId", this.clientId);

        const config = {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: "Bearer" + localStorage.agenteAccessToken,
          },
        };
        const response = await mainAxios.post(
          `/v1/alianza/upload-asesores/${this.idAliance}`,
          // `/v1/alianza/upload-asesores/18`,
          formData,
          config
        );
        this.csvData = response.data;

        if (typeof response.data === "string") {
          const data = this.csvToJson(this.csvData);

          if (data.length) {
            const lastId = data.length;
            let correctCount = 0;
            let duplicateCount = 0;
            let otherCount = 0;
            data.forEach((item) => {
              const resultado = item["Resultado"].trim(); // TODO corregir el nombre de la columna

              if (resultado.includes("Correcto")) {
                correctCount++;
              } else if (resultado.includes("Duplicado")) {
                duplicateCount++;
              } else {
                otherCount++;
              }
            });
            this.reporteCsv = {
              ejecutados: lastId,
              correctos: correctCount,
              duplicados: duplicateCount,
              rechazados: otherCount,
            };
            console.log("Reporte CSV:", this.reporteCsv);
            this.showDetailsCSV();
          }
        } else {
          console.error("La respuesta no está en formato CSV.");
        }
        console.log("Archivo subido correctamente:", response.data);
      } catch (error) {
        console.error("Error al subir el archivo:", error);
      }
    },

    downloadExcelFromCSV() {
      const data = this.csvToJson(this.csvData);

      const worksheet = XLSX.utils.json_to_sheet(data);

      const workbook = {
        Sheets: { Alianzas: worksheet },
        SheetNames: ["Alianzas"],
      };

      const excelBuffer = XLSX.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });

      const blob = new Blob([excelBuffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = "Resultado de carga de Asesores.xlsx";
      link.click();
    },

    returnStep() {
      this.connectSocket();
      this.step = 0;
      this.file = null;
    },
    csvToJson(csv) {
      const lines = csv.split("\n");
      const result = [];
      const headers = lines[0].split(",");

      for (let i = 1; i < lines.length; i++) {
        const currentLine = lines[i].trim();
        if (!currentLine) {
          continue;
        }

        const obj = {};
        const currentLineValues = currentLine.split(",");

        for (let j = 0; j < headers.length; j++) {
          obj[headers[j].trim()] = currentLineValues[j]
            ? currentLineValues[j].trim()
            : "";
        }

        result.push(obj);
      }

      return result;
    },

    setearValores() {
      this.usuario = {
        nombre: "",
        apellidoPaterno: "",
        apellidoMaterno: "",
        rfc: "",
        sexo: "",
        fechaNacimiento: "",
        nacimientoEstadoId: null,
        celular: "",
        // telefono: "",
        correo: "",
        emails: [
          {
            correo: "",
            favorito: true,
          },
        ],
        status: 0,
        ramo: 0,
        canal: 0,
      };
      this.step = 1;
      this.dialog = false;
      this.dialogCsv = false;
    },

    abrirModal() {
      this.dialog = !this.dialog;
    },
    abrirModalCsv() {
      this.dialogCsv = !this.dialog;
    },
    onDragOver() {
      this.isDragging = true; // Detecta que el archivo está sobre el área de carga
    },
    onDragLeave() {
      this.isDragging = false; // Restablece el estado cuando se sale del área
    },
    onDrop(event) {
      this.isDragging = false;
      const droppedFiles = event.dataTransfer.files;

      if (droppedFiles.length && (droppedFiles[0].type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" || droppedFiles[0].type === "application/vnd.ms-excel")) {
        this.file = droppedFiles[0];
      } else {
        alert("Solo se permiten archivos en formato .xlsx o .xls");
      }
    },
    actualizarRfc() {
      if (
        !this.usuario.nombre ||
        !this.usuario.apellidoPaterno ||
        !this.usuario.apellidoMaterno ||
        !this.usuario.fechaNacimiento
      )
        return;
      const arregloNacimiento = moment(this.usuario.fechaNacimiento)
        .format("DD/MM/YYYY")
        .split("/")
        .map((e) => Number(e));
      if (arregloNacimiento.length < 3) return;
      const rfc = RfcFacil.forNaturalPerson({
        name: this.usuario.nombre,
        firstLastName: this.usuario.apellidoPaterno,
        secondLastName: this.usuario.apellidoMaterno,
        day: arregloNacimiento[0],
        month: arregloNacimiento[1],
        year: arregloNacimiento[2],
      });
      this.usuario.rfc = rfc;
      this.usuario.rfcRepresentanteLegal = rfc;
      this.usuario.password = this.usuario.rfc;
    },
    listas() {
      let config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };
      mainAxios.get(`/v1/catalogo/alliance`, config).then((response) => {
        function corregirLabels(branchs) {
          return branchs.map((branch) => {
            if (branch.label === "Danios") {
              branch.label = "Daños";
            }
            return branch;
          });
        }
        corregirLabels(response.data.branchs);
        // console.log(response.data.branchs);

        this.ramoList = response.data.branchs;
        this.canalList = response.data.channels;
      });
      mainAxios.get(`/v1/campania/list`, config).then((response) => {
        this.campaniaList = response.data;
      });

      mainAxios.get(`/v1/agente/landing/list`, config).then(({ data }) => {
        this.landings = data;
      });
      mainAxios.get(`/v1/catalogo/estado/nombres`, config).then(({ data }) => {
        this.estados = data;
      });
    },
    onInputToUpper() {
      this.usuario.rfc = this.usuario.rfc.toUpperCase();
    },
    setNewUser() {
      let config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
          ContentType: "application/json",
        },
      };
      let dataUser = {
        alianzaId: this.idAliance,
        // alianzaId: 95,
        correo: this.usuario.emails[0].correo,
        nombre: this.usuario.nombre,
        apellidoPaterno: this.usuario.apellidoPaterno,
        apellidoMaterno: this.usuario.apellidoMaterno,
        fechaNacimiento: this.usuario.fechaNacimiento,
        // telefono: this.usuario.telefono,
        celular: this.usuario.celular,
        rfc: this.usuario.rfc,
        nacimientoEstadoId: this.usuario.nacimientoEstadoId,
        sexo: this.usuario.sexo,
        // cat_branch_ids: this.usuario.ramo,
        // cat_channel_id: this.usuario.canal,
      };
      console.log(dataUser);

      mainAxios
        .post(`/v1/agente/save-alliance`, dataUser, config)
        .then((response) => {
          console.log(response);
          if (response.data.result == "Correcto") {
            this.stepIndividual++;
          } else {
            this.errorMessage = response.data.result;
            // this.errorMessage = "Ocurrió un error inesperado";
            console.log(this.errorMessage);
            this.stepIndividual = 3;
          }
        });
    },
    showDetailsCSV() {
      setTimeout(() => {
        if (this.progress >= 100) {
          this.step = 1;
        }
      }, 1000);
    },
  },
  mounted() {
    this.listas();
    this.connectSocket();
  },

  watch: {
    "usuario.fechaNacimiento"() {
      this.actualizarRfc();
    },
    "usuario.nombre"() {
      this.actualizarRfc();
    },
    "usuario.apellidoPaterno"() {
      this.actualizarRfc();
    },
    "usuario.apellidoMaterno"() {
      this.actualizarRfc();
    },
  },

  computed: {
    validador() {
  const {
    nombre,
    apellidoPaterno,
    apellidoMaterno,
    emails,
    nacimientoEstadoId,
    rfc,
    celular,
    sexo,
    fechaNacimiento,
  } = this.usuario;

  // Definir las condiciones de validación
  const validaciones = [
    nombre && nombre.length > 0,
    apellidoPaterno && apellidoPaterno.length > 0,
    apellidoMaterno && apellidoMaterno.length > 0,
    emails && emails[0] && emails[0].correo && emails[0].correo.length > 0,
    nacimientoEstadoId && nacimientoEstadoId > 0,
    rfc && rfc.length > 0,
    celular && celular.length > 9,
    sexo && sexo > 0,
    fechaNacimiento && fechaNacimiento.length > 0,
  ];

  const esValido = validaciones.every((validacion) => validacion);

  return esValido ? false : true;
}
  },
};
</script>
<style scoped>
.v-card {
  border: 1px dashed #ccc;
  border-radius: 8px;
  background-color: #f6f5f9;
  transition: background-color 0.2s ease;
}
.form-card {
  border: none;
  background-color: #f7f8fac9;
  border-radius: 20px;
  padding: 20px;
  margin-top: 20px;
}

.drag-active {
  background-color: #e3f2fd;
}

.asesor {
  background-color: #00a5df !important;
  color: white !important;
}
.btn-archivo {
  background-color: #7d8b99 !important;
  color: white !important;
}

.format-drag {
  font-family: "Montserrat";
  color: #039ecc;
  margin: 0;
  font-size: 11px;
  font-weight: 500;
  line-height: 15px;
  text-align: left;
}
.text-drag {
  margin-bottom: 10px;
  color: #4d5358;
  font-size: 15px;
  font-weight: 500;
}
.icon-upload {
  max-width: 53.77px;
  height: 48px;
}
</style>
