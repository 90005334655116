<template>
  <CommonCard>
    <div>
      <v-row style="padding: 30px;">
        <v-col cols="6">
          <span class="titulo-text">
            Beneficiarios
          </span>
        </v-col>
        <v-col>
          <v-col
            cols="12"
            style="text-align: end;"
            v-if="currentPorcentaje < 100 && beneficiarios.length < 5"
          >
            <v-btn
              class="common-botton mr-4"
              @click="
                (dialogBeneficiarios = true),
                  (beneficiarioId = null),
                  (readOnly = false)
              "
              dark
            >
              Crear Beneficiario
            </v-btn>
          </v-col>
        </v-col>
      </v-row>
      <v-row style="padding: 30px; padding-top: 0px;">
        <v-col cols="12">
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-center">
                    <span class="text-header-table">
                      Nombre
                    </span>
                  </th>
                  <th class="text-center">
                    <span class="text-header-table">
                      Parentesco
                    </span>
                  </th>
                  <th class="text-center">
                    <span class="text-header-table">
                      Porcentaje
                    </span>
                  </th>
                  <th class="text-center">
                    <span class="text-header-table">
                      Fecha de alta
                    </span>
                  </th>
                  <th class="text-center">
                    <span class="text-header-table">
                      Acciones
                    </span>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in beneficiarios" :key="item.id">
                  <td class="text-center">
                    <span class="text-table">
                      {{ getNombres(item) }}
                    </span>
                  </td>
                  <td class="text-center">
                    <span class="text-table">
                      {{ getParentesco(item) }}
                    </span>
                  </td>
                  <td class="text-center">
                    <span class="text-table">
                      {{ item.porcentaje + "%" }}
                    </span>
                  </td>
                  <td class="text-center">
                    <span class="text-table">
                      {{ getFormattDate(item.fechaCreacion) }}
                    </span>
                  </td>
                  <td class="text-center">
                    <v-btn
                      icon
                      color="primary"
                      @click="openBeneficiarioDetalle(item.id)"
                      class="mr-2"
                    >
                      <v-icon>mdi-eye</v-icon>
                    </v-btn>
                    <v-btn
                      icon
                      color="warning"
                      @click="openBeneficiarioEdicion(item.id)"
                    >
                      <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-col>
      </v-row>
    </div>
    <CreateBeneficiarioModal
      :solicitud_id="solicitud_id"
      :dialog="dialogBeneficiarios"
      :beneficiarioId="beneficiarioId"
      :readOnly="readOnly"
      :currentPorcentaje="currentPorcentaje"
      @closeModal="dialogBeneficiarios = false"
      @getBeneficiarios="getBeneficiariosEvent"
    />
  </CommonCard>
</template>

<script>
import CommonCard from "@/components/commonComponents/CommonCard.vue";
import moment from "moment";
import CreateBeneficiarioModal from "@/components/commonComponents/dialogs/CreateBeneficiarioModal.vue";
import { getParentescoList } from "@/services/beneficiarios/benefiario.service.js";

export default {
  components: {
    CommonCard,
    CreateBeneficiarioModal,
  },
  props: {
    beneficiarios: Array,
  },
  data() {
    return {
      solicitud_id: this.$route.params.id,
      tab: 1,
      dialogBeneficiarios: false,
      beneficiarioId: null,
      readOnly: false,
      parentescoList: [],
    };
  },

  computed: {
    currentPorcentaje() {
      let total = 0;
      this.beneficiarios.map((e) => {
        total = Number(e.porcentaje) + Number(total);
      });
      return total;
    },
  },
  methods: {
    getParentesco(item){
      let parentesco = this.parentescoList.find((e) => e.valorSegurify == item.parentesco)
      return parentesco ? parentesco.valor : 'No disponible'
    },
    getNombres(item) {
      return `${item.nombre || ""} ${item.apellidoPaterno ||
        ""} ${item.apellidoMaterno || ""}`.trim();
    },
    getFormattDate(date) {
      return moment(date, "YYYY-MM-DD HH:mm:ss").format("DD/MM/YYYY");
    },
    openBeneficiarioDetalle(id) {
      this.beneficiarioId = id;
      this.dialogBeneficiarios = true;
      this.readOnly = true;
    },
    openBeneficiarioEdicion(id) {
      this.beneficiarioId = id;
      this.dialogBeneficiarios = true;
      this.readOnly = false;
    },
    getBeneficiariosEvent(){
      this.dialogBeneficiarios = false;
      this.$emit("getBeneficiarios")
    },
    async getParentescoList() {
      let response = await getParentescoList();
      this.parentescoList = response.data.parentescos
    },
  },
  mounted(){
    this.getParentescoList()
  }
};
</script>

<style>
.titulo-text {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 110%;
  color: #4d5358;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
}

.text-header-table {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 120%;
  color: #000000;
}

.text-table {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 120%;
  color: #000000;
}
</style>
