var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-row',{staticStyle:{"padding":"0px 20px"}},[_c('v-col',{attrs:{"cols":"4"}},[_c('v-autocomplete',{attrs:{"label":"Modelo","placeholder":"Requerido","items":_vm.modelos,"item-value":"id","item-text":"id","auto-select-first":""},on:{"change":function($event){_vm.getMarcas();
        _vm.avisarCambio();}},model:{value:(_vm.modelo),callback:function ($$v) {_vm.modelo=$$v},expression:"modelo"}})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-autocomplete',{attrs:{"label":"Marca","placeholder":"Requerido","items":_vm.marcas,"item-value":"marca","item-text":"marca","no-data-text":"Primero selecciona un modelo","auto-select-first":""},on:{"change":function($event){_vm.getSubmarcas();
        _vm.avisarCambio();}},model:{value:(_vm.marca),callback:function ($$v) {_vm.marca=$$v},expression:"marca"}})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-autocomplete',{attrs:{"label":"Submarca","placeholder":"Requerido","items":_vm.submarcas,"item-value":"nombre","item-text":"nombre","no-data-text":"Primero selecciona una marca","auto-select-first":""},on:{"change":function($event){_vm.getVersiones();
        _vm.avisarCambio();}},model:{value:(_vm.submarca),callback:function ($$v) {_vm.submarca=$$v},expression:"submarca"}})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-autocomplete',{attrs:{"label":"Version","placeholder":"Requerido","items":_vm.versiones,"item-value":"nombre","item-text":"nombre","no-data-text":"Primero selecciona una submarca","auto-select-first":""},on:{"change":function($event){return _vm.avisarCambio()}},model:{value:(_vm.version),callback:function ($$v) {_vm.version=$$v},expression:"version"}})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-textarea',{attrs:{"label":"Detalle de la Póliza","rows":"1","auto-grow":"","rules":_vm.inputsRequeridos},on:{"change":_vm.avisarCambio},model:{value:(_vm.detallePoliza),callback:function ($$v) {_vm.detallePoliza=$$v},expression:"detallePoliza"}})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-select',{attrs:{"label":"Plan","items":_vm.planList,"item-text":"name","item-value":"id","rules":_vm.inputsRequeridos},on:{"change":_vm.avisarCambio},model:{value:(_vm.plan),callback:function ($$v) {_vm.plan=$$v},expression:"plan"}})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-select',{attrs:{"label":"Origen del Auto","items":_vm.origenAutoList,"rules":_vm.inputsRequeridos},on:{"change":_vm.avisarCambio},model:{value:(_vm.origenAuto),callback:function ($$v) {_vm.origenAuto=$$v},expression:"origenAuto"}})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-select',{attrs:{"label":"Uso del Auto","items":_vm.usoAutoList,"rules":_vm.inputsRequeridos},on:{"change":_vm.avisarCambio},model:{value:(_vm.usoAuto),callback:function ($$v) {_vm.usoAuto=$$v},expression:"usoAuto"}})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-text-field',{attrs:{"label":"No de Motor","rules":_vm.inputsRequeridos},on:{"change":_vm.avisarCambio},model:{value:(_vm.noMotor),callback:function ($$v) {_vm.noMotor=$$v},expression:"noMotor"}})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-text-field',{attrs:{"label":"No. De Serie","maxLength":17,"rules":[
        ..._vm.inputsRequeridos,
        _vm.localRules.alphanumeric,
        _vm.$rules.min(15),
        _vm.$rules.max(17),
      ]},on:{"change":_vm.avisarCambio},model:{value:(_vm.noSerie),callback:function ($$v) {_vm.noSerie=$$v},expression:"noSerie"}})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-text-field',{attrs:{"label":"Placas","maxLength":10,"rules":[..._vm.inputsRequeridos, _vm.localRules.alphanumeric]},on:{"change":_vm.avisarCambio},model:{value:(_vm.placas),callback:function ($$v) {_vm.placas=$$v},expression:"placas"}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }