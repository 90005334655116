var render = function render(){var _vm=this,_c=_vm._self._c;return _c('common-card',{staticClass:"my-4"},[_c('v-card-title',[_c('h3',[_vm._v("Datos personales")])]),_c('v-card-text',[_c('v-col',{attrs:{"cols":"12"}},[_c('span',{staticClass:"mt-negative mb-5"},[_vm._v("Introduce los datos del titular del vehículo")]),_c('v-row',{staticClass:"mt-3"},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('common-email-autocomplete',{attrs:{"emailEdit":_vm.personalData.correo},on:{"emailUpdate":(e) => (_vm.personalData.correo = e)}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{attrs:{"label":"Teléfono*","rules":_vm.phoneValidation,"type":"tel","outlined":"","maxlength":"10"},model:{value:(_vm.personalData.telefono),callback:function ($$v) {_vm.$set(_vm.personalData, "telefono", $$v)},expression:"personalData.telefono"}})],1)],1),_c('v-row'),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-text-field',{attrs:{"label":"Nombre*","rules":[
              ..._vm.required,
              _vm.$rules.nameRule('Ingresa un nombre válido'),
            ],"required":"","outlined":""},model:{value:(_vm.personalData.nombre),callback:function ($$v) {_vm.$set(_vm.personalData, "nombre", $$v)},expression:"personalData.nombre"}})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-text-field',{attrs:{"label":"Apellido Paterno*","rules":[
              ..._vm.required,
              _vm.$rules.nameRule('Ingresa un apellido válido'),
            ],"required":"","outlined":""},model:{value:(_vm.personalData.apellidoPaterno),callback:function ($$v) {_vm.$set(_vm.personalData, "apellidoPaterno", $$v)},expression:"personalData.apellidoPaterno"}})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-text-field',{attrs:{"label":"Apellido Materno","outlined":""},model:{value:(_vm.personalData.apellidoMaterno),callback:function ($$v) {_vm.$set(_vm.personalData, "apellidoMaterno", $$v)},expression:"personalData.apellidoMaterno"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-text-field',{attrs:{"label":"Fecha de nacimiento*","rules":_vm.required,"required":"","type":"date","outlined":""},model:{value:(_vm.personalData.fechaDeNacimiento),callback:function ($$v) {_vm.$set(_vm.personalData, "fechaDeNacimiento", $$v)},expression:"personalData.fechaDeNacimiento"}})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-text-field',{attrs:{"label":"Código postal*","required":"","rules":_vm.cpValidation,"outlined":""},model:{value:(_vm.personalData.cp),callback:function ($$v) {_vm.$set(_vm.personalData, "cp", $$v)},expression:"personalData.cp"}})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('p',[_vm._v("Genero*")]),_c('v-radio-group',{attrs:{"mandatory":""},model:{value:(_vm.personalData.genero),callback:function ($$v) {_vm.$set(_vm.personalData, "genero", $$v)},expression:"personalData.genero"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-radio',{attrs:{"label":"Femenino","value":"F","color":"primary"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-radio',{attrs:{"label":"Masculino","value":"M","color":"primary"}})],1)],1)],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }