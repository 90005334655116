var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',[_c('v-row',{staticClass:"pt-5 pb-5"},[_c('v-col',{attrs:{"cols":"10"}},[_c('span',{staticClass:"titulo-header"},[_vm._v(" Detalle de póliza ")])]),_c('v-col',{staticStyle:{"text-align":"end"},attrs:{"cols":"2"}},[_c('h2',[_vm._v("#"+_vm._s(_vm.polizaId))])])],1)],1),_c('info-card-solicitudes',{attrs:{"infoCards":_vm.infoCards,"emailRoutesConfig":_vm.emailRoutesConfig},on:{"getData":_vm.updateView,"toCuotas":function($event){return _vm.tabReplaceSelect(3)}}}),_c('div',[_c('v-row',{staticStyle:{"padding-top":"60px"}},[_c('v-col',{class:_vm.tabReplace == 1
            ? 'text-center active-color'
            : 'text-center inactive-color',attrs:{"cols":_vm.calculateColumns},on:{"click":function($event){return _vm.tabReplaceSelect(1)}}},[_c('span',{class:_vm.tabReplace == 1
              ? 'text-header-step-active'
              : 'text-header-step-no-active'},[_vm._v(" Póliza")])]),_c('v-col',{class:_vm.tabReplace == 2
            ? 'text-center active-color'
            : 'text-center inactive-color',attrs:{"cols":_vm.calculateColumns},on:{"click":function($event){return _vm.tabReplaceSelect(2)}}},[_c('span',{class:_vm.tabReplace == 2
              ? 'text-header-step-active'
              : 'text-header-step-no-active'},[_vm._v(" Cliente ")])]),(_vm.cuotas.length > 0)?_c('v-col',{class:_vm.tabReplace == 3
            ? 'text-center active-color'
            : 'text-center inactive-color',attrs:{"cols":_vm.calculateColumns},on:{"click":function($event){return _vm.tabReplaceSelect(3)}}},[_c('span',{class:_vm.tabReplace == 3
              ? 'text-header-step-active'
              : 'text-header-step-no-active'},[_vm._v(" Cuotas")])]):_vm._e(),(_vm.productHasBeneficiario)?_c('v-col',{class:_vm.tabReplace == 4
            ? 'text-center active-color'
            : 'text-center inactive-color',attrs:{"cols":_vm.calculateColumns},on:{"click":function($event){return _vm.tabReplaceSelect(4)}}},[_c('span',{class:_vm.tabReplace == 4
              ? 'text-header-step-active'
              : 'text-header-step-no-active'},[_vm._v(" Beneficiarios")])]):_vm._e()],1)],1),_c('div',{staticStyle:{"padding-top":"60px"}},[(_vm.tabReplace == 1)?_c('div',[_c('PolizaInfoIndex',{attrs:{"polizaInfo":_vm.normalPolizaData,"productos":_vm.productos,"customData":_vm.polizaInfo,"ramoData":_vm.ramoData},on:{"getData":_vm.updateView}})],1):_vm._e(),(_vm.tabReplace == 2)?_c('div',[_c('div',[_c('ClienteSolicitudIndex',{attrs:{"polizaInfo":_vm.normalPolizaData},on:{"getData":_vm.updateView}})],1),_c('div',[(
            _vm.rol == 'MESACONTROLINBOUND' ||
              _vm.rol == 'OPERADORINBOUND' ||
              _vm.rol == 'OPERACIONESINBOUND' ||
              _vm.rol == 'ADMIN'
          )?_c('EmbeddedCliente',{attrs:{"telefonos":_vm.telefonosCliente,"cliente_id":_vm.polizaInfo.cliente,"is_poliza":true},on:{"success":_vm.updateView}}):_vm._e()],1)]):_vm._e(),(_vm.tabReplace == 3)?_c('div',[_c('div',[_c('DataCuotasIndex',{attrs:{"cuotas":_vm.cuotas}})],1)]):_vm._e(),(_vm.tabReplace == 4)?_c('div',[_c('BeneficiariosIndex',{attrs:{"beneficiarios":_vm.beneficiarios},on:{"getBeneficiarios":_vm.getBeneficiariosByEmisionId}})],1):_vm._e()]),_c('CreateBeneficiarioModal',{attrs:{"emision_id":_vm.polizaId,"dialog":_vm.dialogBeneficiarios},on:{"closeModal":_vm.closeModalBeneficiario}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }