var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-app',[_c('v-content',[_c('v-container',{staticStyle:{"background-color":"ghostwhite"},attrs:{"fluid":""}},[_c('v-row',[_c('v-col',[_c('transition',{attrs:{"name":"fade"}},[_c('v-row',{staticClass:"mb-15",attrs:{"align":"center","justify":"center"}},[_c('v-carousel',{attrs:{"cycle":"","height":"505","hide-delimiter-background":"","show-arrows":"hover"}},_vm._l((_vm.banners),function(banner,i){return _c('v-carousel-item',{key:i},[_c('v-sheet',{attrs:{"height":"100%"}},[_c('div',{staticClass:"d-flex fill-height justify-center align-center"},[_c('img',{staticClass:"bannerHome",attrs:{"src":'img/bannersHome/' + banner.url,"alt":""}})])])],1)}),1)],1)],1)],1)],1),_c('div',{staticClass:"mb-15",staticStyle:{"text-align":"center"}},[_c('h1',[_vm._v(" \"Encuentra tu seguro ideal\" ")]),_c('h3',{staticStyle:{"border-bottom":"2px solid #172184","padding-bottom":"5px"}},[_vm._v(" No pierdas más tiempo buscando en diferentes lugares, encuentra todo lo que necesitas en nuestro hub de cotizadores de autos. ¡Comienza a explorar y descubre la mejor opción para ti! ")])]),_c('div',[_c('v-row',[_vm._l((_vm.items),function(item,i){return [_c('v-col',{key:i,attrs:{"cols":"6","md":"12","sm":"12","xs":"12","lg":"6","xl":"6"},on:{"click":function($event){return _vm.$router.push({ path: item.route })}}},[_c('div',{staticClass:"projcard-container"},[_c('div',{class:item.title == 'Segurify Comparador'
                      ? 'projcard projcard-blue'
                      : item.title == 'Qualitas'
                      ? 'projcard projcard-qualitas'
                      : item.title == 'GNP'
                      ? 'projcard projcard-gnp'
                      : item.title == 'AIG'
                      ? 'projcard projcard-blue'
                      : item.title == 'Ana'
                      ? 'projcard projcard-ana'
                      : item.title == 'HDI'
                      ? 'projcard projcard-hdi'
                      : 'projcard projcard-blue'},[_c('div',{staticClass:"projcard-innerbox"},[_c('img',{staticClass:"projcard-img",attrs:{"src":item.background}}),_c('div',{staticClass:"projcard-textbox"},[_c('div',{staticClass:"projcard-title"},[_c('img',{staticStyle:{"max-width":"100%","max-height":"100px"},attrs:{"src":item.img}})]),_c('div',{staticClass:"projcard-bar"}),_c('div',{staticClass:"projcard-description"},[_vm._v(_vm._s(item.text))]),_c('div',{staticClass:"projcard-tagbox"},[_c('span',{staticClass:"projcard-tag",staticStyle:{"font-size":"16px !important"}},[_vm._v("Cotiza Ahora !")])])])])])])])]})],2)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }